import { useState } from 'react';

const Input = (props) => {

    return (
        <div className="input-wrapper">
            <input type="text" value={props.value} placeholder={props.placeholder} onChange={(e) => props.setFunction(e.target.value)}></input>
        </div>
    )
}

export default Input;