import React, { useState, useEffect, useRef} from "react"
import { Editor } from '@tinymce/tinymce-react';

export default props => {
    let [error, setError] = useState(false);
    let [load, setLoad] = useState(false);
    let [errorText, setErrorText] = useState(false);
    let [ openMediaLoad, setOpenMediaLoad ] = useState(false);
    const editorRef = useRef(null);

    useEffect(() => {
        if (!load) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if (load) {
            validate();
        }
    }, [props.forceValidate])

    const validate = () => {
        if (props.required) {
            let value = '';
            if(props.value && (props.value).length > 0) {
                value = props.value.replace(/<[^>]*>?/gm, '');
            }
            if (value && (value).length == 0 || !value) {
                setError(true)
                setErrorText('To pole jest wymagane.');
            } else {
                setError(false);
                setErrorText('');
            }
        }
    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    const replaceImg = (html) => {
        return replaceAll(html, "<p><img", "<p class='parent-img'><img");
    }

    const updateSrc = (imageData) => {
        let input = document.querySelectorAll('.tox-dialog .tox-form__controls-h-stack .tox-textfield');
        if(input) {
            console.log(input[0].value = imageData.src);
        }
    } 

    return (
        <div className={error ? "editor error" : "editor"} data-alert={errorText}>
            {props.label &&
                <label>{props.label}</label>
            }
            <Editor
                // onInit={(evt, editor) => editorRef.current = editor}
                ref={editorRef}
                initialValue={props.value ? props.value : ""}
                init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'code',
                    ],
                    images_file_types: 'jpg,png',
                    file_picker_callback: (callback, value, meta) => {
                        setOpenMediaLoad(true);
                    },
                    language: "pl",
                    entity_encoding : "raw",
                    selector: "textarea",
                    valid_elements: "*[*]",
                    toolbar: 'formatselect | ' +
                    'bold | italic ' +
                    'code',
                    setup: function (editor) {},
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    init_instance_callback: function (editor) {
                        let allToolbar = document.querySelectorAll(".editor .tox-editor-header [tabIndex='-1']");
                        for(let i = 0; i<allToolbar.length; i++) {
                            allToolbar[i].setAttribute("tabIndex", "0");
                        }
                    }
                }}
                onBlur={(e) => props.setFunction(replaceImg(e.target.getContent())) }
            />
        </div>
    )
}