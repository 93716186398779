import { useState, useEffect } from 'react';
import Input from './components/input';
import Button from './components/button';
import Editor from './components/editor';
import Textarea from './components/textarea';

function App() {
  let [ btnTitle, setBtnTitle ] = useState("PŁACĘ Z GRENKE");
  let [ btnHref, setBtnHref ] = useState("https://newonline.leasingoptymalny.pl/");
  let [ desc, setDesc ] = useState("Najlepsze metody finansowania dla Twojej firmy. Wybierz leasing lub najem grenke jako metodę płatności. Odpisuj raty w koszty działalności firmy.");
  let [ faq1, setFaq1 ] = useState({
      title: "OFERTA",
      editor: "<h4>LEASING</h4><p>Skorzystaj z leasingu z grenke. Wybierz płatność kwartalną, by efektywniej zarządzać budżetem.</p><h4>NAJEM</h4><p>Używaj sprzętu dokładnie jak potrzebujesz. Na koniec wymień sprzęt na nowszy, wykup go, przedłuż umowę lub ją zakończ. To Ty decydujesz.</p>"
  });
  let [ faq2, setFaq2 ] = useState({
    title: "KROKI ONLINE",
    editor: "<p>Złóż wniosek online, zweryfikuj się i odbierz decyzję. Wybierz wygodny i bezpieczny e-podpis umowy</p>"
  })
  let [ faq3, setFaq3 ] = useState({
    title: "WYGODNY ODBIÓR ZAKUPÓW",
    editor: "<p>Zakupy odbierasz tak, jak przy każdym innym sposobie płatności dostępnym w sklepie – od kuriera lub w punkcie stacjonarnym.</p>"
  })
  let [ navBtnTitle1, setNavBtnTitle1 ] = useState("FAQ");
  let [ navBtnHref1, setNavBtnHref1 ] = useState("https://newonline.leasingoptymalny.pl?a=faq");
  let [ navBtnTitle2, setNavBtnTitle2 ] = useState("O GRENKE");
  let [ navBtnHref2, setNavBtnHref2 ] = useState("https://www.grenke.pl/");
  let [ navBtnTitle3, setNavBtnTitle3 ] = useState("KALKULATOR OFERT");
  let [ navBtnHref3, setNavBtnHref3 ] = useState("https://newonline.leasingoptymalny.pl");

  const generateFile = () => {
    let el = document.getElementById( 'wrapper-sidebar-container' );
    let innerHTML = el.outerHTML;
    let fileContent = `let container = document.getElementById('sidebar-container');
      container.innerHTML = '${innerHTML}';
      if(typeof window !== 'undefined') {
        const handleClick = (e) => {
          e.currentTarget.parentElement.classList.toggle('active')
        }

        let faqClick = document.querySelectorAll("#sidebar-container .wrapper .hidden .faq .row .header-faq");
        if(faqClick) {
          for (var i = 0; i < faqClick.length; i++) {
            faqClick[i].addEventListener('click', handleClick);
          }
          if(window.innerWidth <= 500) {
            document.getElementById('visible-btn-sidebar').addEventListener('click', function(e) {
              e.preventDefault();
            })
          } 
        }

        var head  = document.getElementsByTagName('head')[0];
        var link  = document.createElement('link');
        link.id   = 'sidebar-css';
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://widget-grenke.hekko24.pl/sidebar.css';
        link.media = 'all';
        head.appendChild(link);
      }
    `;
    const fileData = fileContent;
    const blob = new Blob([fileData], { type: "text/javascript" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "sidebar.js";
    link.href = url;
    link.click();
  }

  useEffect(() => {
    if(typeof window !== 'undefined') {
      let faqClick = document.querySelectorAll("#sidebar-container .wrapper .hidden .faq .row .header-faq");
      if(faqClick) {
        for (var i = 0; i < faqClick.length; i++) {
          faqClick[i].addEventListener('click', handleClick);
        }
      }
      if(window.innerWidth <= 500) {
        document.getElementById('visible-btn-sidebar').addEventListener('click', function(e) {
          e.preventDefault();
        })
      } 
    }
  }, [])

  const handleClick = (e) => {
    e.currentTarget.parentElement.classList.toggle('active')
  }

  return (
    <div className="App">
      <div className="form">
        <div className="row">
          <Input value={btnTitle} placeholder="PŁACĘ Z GRENKE" setFunction={(value) => setBtnTitle(value) } />
          <Input value={btnHref} placeholder="Link do PŁACĘ Z GRENKE" setFunction={(value) => setBtnHref(value) } />
        </div>
        <div className="row">
          <Textarea setFunction={ val => setDesc(val) } value={desc} placeholder="Najlepsze metody finansowania dla Twojej firmy. Wybierz leasing lub najem grenke jako metodę płatności. Odpisuj raty w koszty działalności firmy." />
        </div>
        <div className="row row-editor">
          <Input value={faq1.title} placeholder="OFERTA" setFunction={(value) => setFaq1({ title: value, editor: faq1.editor}) } />
          <Editor setFunction={ val => setFaq1({ title: faq1.title, editor: val}) } value={faq1.editor} />
        </div>
        <div className="row row-editor">
          <Input value={faq2.title} placeholder="KROKI ONLINE" setFunction={(value) => setFaq2({ title: value, editor: faq2.editor}) } />
          <Editor setFunction={ val => setFaq2({ title: faq2.title, editor: val}) } value={faq2.editor} />
        </div>
        <div className="row row-editor">
          <Input value={faq3.title} placeholder="WYGODNY ODBIÓR ZAKUPÓW " setFunction={(value) => setFaq3({ title: value, editor: faq3.editor}) } />
          <Editor setFunction={ val => setFaq3({ title: faq3.title, editor: val}) } value={faq3.editor} />
        </div>
        <div className="row">
          <Input value={navBtnTitle1} placeholder="FAQ" setFunction={(value) => setNavBtnTitle1(value) } />
          <Input value={navBtnHref1} placeholder="Link do FAQ" setFunction={(value) => setNavBtnHref1(value) } />
        </div>
        <div className="row">
          <Input value={navBtnTitle2} placeholder="O GRENKE" setFunction={(value) => setNavBtnTitle2(value) } />
          <Input value={navBtnHref2} placeholder="Link do O GRENKE" setFunction={(value) => setNavBtnHref2(value) } />
        </div>
        <div className="row">
          <Input value={navBtnTitle3} placeholder="KALKULATOR OFERT" setFunction={(value) => setNavBtnTitle3(value) } />
          <Input value={navBtnHref3} placeholder="Link do KALKULATOR OFERT" setFunction={(value) => setNavBtnHref3(value) } />
        </div>
        <div className="row">
          <Button action={() => generateFile()} title="Wygeneruj plik .js" />
        </div>
      </div>
      <div className="information">
        <p>Wygenerowany plik javascript odzwierciedla 1:1 widok widgetu widniejącego, również jeśli zostanie coś rozwinięte to tak zostanie załadowane na stronie klienta (rozwinięte).</p>
        <h2 className="head-info">Instrukcja podpięcia</h2>
        <p>1. Wygeneruj plik javascript</p>
        <p>2. Wygenerowany plik prześlij do klienta</p>
        <p>3. Klient musi podpiąć plik javascript przed zamknięciem znacznika HTML body wraz z WŁĄSNĄ ŚCIEŻKĄ DO TEGO PLIKU - <a onClick={() => alert('<script type="text/javascript" src="https://DOMENA_KLIENTA/ŚCIEŻKA_DO_PLIKU/sidebar.js"></script>')}>pokaż</a></p>
        <p>4. W body należy umieścić fragment kodu html - <a onClick={() => alert('<div id="sidebar-container" style="display: none"></div>')}>pokaż</a></p>
        <p>5. Widget powinien się pojawić na stronie</p>
      </div>
      <div id="sidebar-container"> 
        <div className="wrapper" id="wrapper-sidebar-container">
          <a href={btnHref} className="visible" id="visible-btn-sidebar">
            <div className="svg arrow-svg">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12"><defs></defs><title>Zasób 2</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_5" data-name="Warstwa 5"><polygon class="cls-1" points="0.43 12 0 11.45 5.97 6 0 0.55 0.43 0 7 6 0.43 12"/></g></g></svg>
            </div>
            <p>{btnTitle}</p>
            <div className="svg cash-svg">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 70"><defs></defs><g id="a90d2f44-9755-44cb-a63d-1aa9ea7cd7e5" data-name="Warstwa 2"><g id="f08a4ea9-affd-4368-9e1f-28577a8c96fb" data-name="Icon"><path class="b2b6be9f-a964-4200-b524-b627b7f965e6" d="M62.38,50.65A5.87,5.87,0,0,0,54.52,48l-13.63,6.7a5.83,5.83,0,0,0-5.65-7.38H9.72a1,1,0,0,0-.71.3l-7,7H0v2H2.43a1,1,0,0,0,.71-.29l7-7H35.24a3.86,3.86,0,0,1,0,7.72h-17a1,1,0,0,0,0,2h17a5.87,5.87,0,0,0,2.47-.55h0l17.7-8.71a3.86,3.86,0,0,1,5.18,1.73,3.86,3.86,0,0,1-1.72,5.18L36.36,68H0v2H36.59a1,1,0,0,0,.45-.11L59.76,58.51a5.86,5.86,0,0,0,2.62-7.86Z"/><path class="b2b6be9f-a964-4200-b524-b627b7f965e6" d="M62,0H14a1,1,0,0,0,0,2H61V28H59V5a1,1,0,0,0-1-1H10A1,1,0,0,0,9,5V8H6A1,1,0,0,0,5,9V37a1,1,0,0,0,1,1H54a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H11V6H57V33a1,1,0,0,0,2,0V30h3a1,1,0,0,0,1-1V1A1,1,0,0,0,62,0ZM12.92,10A7,7,0,0,1,7,15.92V10Zm2,0H45.06A9,9,0,0,0,53,17.94V28.06A9,9,0,0,0,45.06,36H14.94A9,9,0,0,0,7,28.06V17.94A9,9,0,0,0,14.94,10ZM7,30.08A7,7,0,0,1,12.92,36H7ZM47.08,36A7,7,0,0,1,53,30.08V36ZM53,15.92A7,7,0,0,1,47.08,10H53Z"/><path class="b2b6be9f-a964-4200-b524-b627b7f965e6" d="M30,31a8,8,0,1,0-8-8A8,8,0,0,0,30,31Zm0-14a6,6,0,1,1-6,6A6,6,0,0,1,30,17Z"/></g></g></svg>
            </div>
          </a>
          <div className="hidden">
            <div className="header">
              <div className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 34.23"><title>Zasób 1</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_2-2" data-name="Warstwa 2"><path class="cls-1" d="M18.22,22.47h4.44a4.21,4.21,0,0,1-1.93,2.73,6.27,6.27,0,0,1-3.29.89,7,7,0,0,1-4.64-1.5,7.27,7.27,0,0,1-2.19-3.37,12,12,0,0,1-.55-3.55,21.74,21.74,0,0,1,.2-3.47A8.17,8.17,0,0,1,12,10.12q1.41-1.86,4.8-2a4.76,4.76,0,0,1,4.76,4.07h9.66a14.88,14.88,0,0,0-5-9Q22.39,0,16.11,0A16.68,16.68,0,0,0,8.66,1.76,14.93,14.93,0,0,0,2.54,7.38,19.08,19.08,0,0,0,0,17.44a19.05,19.05,0,0,0,1.9,8.49,14.82,14.82,0,0,0,5.37,6,15.38,15.38,0,0,0,8.34,2.28,13.28,13.28,0,0,0,5.14-1,10.27,10.27,0,0,0,3.05-1.94v2.14h7.78v-18H18.22Z"/><path class="cls-1" d="M53.11,14.53a6.56,6.56,0,0,1-2.92.53H44.92V8.55h4.72a6.76,6.76,0,0,1,3.94.86,2.74,2.74,0,0,1,1.05,2.4,2.77,2.77,0,0,1-1.52,2.72M64.63,28.76c0-.6-.1-1.78-.14-2.34A12.67,12.67,0,0,0,63,21.27a6.45,6.45,0,0,0-3.31-3A7,7,0,0,0,63.4,15a10.37,10.37,0,0,0,1.19-4.87,9.16,9.16,0,0,0-1.5-5.18A9,9,0,0,0,59.3,1.86,12.77,12.77,0,0,0,54,.78H34.74V33.46H44.8V22.06h5.48a3.9,3.9,0,0,1,2.82,1,4.51,4.51,0,0,1,1.18,2.33A54.25,54.25,0,0,1,55,33.46h9.84s0-1.74-.11-3.13c0-.77-.06-1.42-.07-1.57"/><polygon class="cls-1" points="77.82 21 93.25 21 93.25 13.23 77.82 13.23 77.82 9.15 94.8 9.15 94.8 0.78 67.75 0.78 67.75 33.46 95.31 33.46 95.31 25.09 77.82 25.09 77.82 21"/><polygon class="cls-1" points="98.46 33.46 107.87 33.46 107.87 15.6 117.88 33.46 118.13 33.46 127.4 33.46 127.4 23.8 130.82 20.74 138.79 33.46 151.19 33.46 137.57 13.55 149.29 0.78 137.03 0.78 127.4 12.04 127.4 0.78 118.06 0.78 118.06 18.26 108.66 0.78 98.46 0.78 98.46 33.46"/><polygon class="cls-1" points="152.45 0.78 152.45 33.46 180 33.46 180 25.09 162.51 25.09 162.51 21.01 177.94 21.01 177.94 13.23 162.51 13.23 162.51 9.15 179.5 9.15 179.5 0.78 152.45 0.78"/></g></g></svg>
              </div>
              <div className="row">
                <div className="svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 70"><defs></defs><g id="a90d2f44-9755-44cb-a63d-1aa9ea7cd7e5" data-name="Warstwa 2"><g id="f08a4ea9-affd-4368-9e1f-28577a8c96fb" data-name="Icon"><path class="b2b6be9f-a964-4200-b524-b627b7f965e6" d="M62.38,50.65A5.87,5.87,0,0,0,54.52,48l-13.63,6.7a5.83,5.83,0,0,0-5.65-7.38H9.72a1,1,0,0,0-.71.3l-7,7H0v2H2.43a1,1,0,0,0,.71-.29l7-7H35.24a3.86,3.86,0,0,1,0,7.72h-17a1,1,0,0,0,0,2h17a5.87,5.87,0,0,0,2.47-.55h0l17.7-8.71a3.86,3.86,0,0,1,5.18,1.73,3.86,3.86,0,0,1-1.72,5.18L36.36,68H0v2H36.59a1,1,0,0,0,.45-.11L59.76,58.51a5.86,5.86,0,0,0,2.62-7.86Z"/><path class="b2b6be9f-a964-4200-b524-b627b7f965e6" d="M62,0H14a1,1,0,0,0,0,2H61V28H59V5a1,1,0,0,0-1-1H10A1,1,0,0,0,9,5V8H6A1,1,0,0,0,5,9V37a1,1,0,0,0,1,1H54a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H11V6H57V33a1,1,0,0,0,2,0V30h3a1,1,0,0,0,1-1V1A1,1,0,0,0,62,0ZM12.92,10A7,7,0,0,1,7,15.92V10Zm2,0H45.06A9,9,0,0,0,53,17.94V28.06A9,9,0,0,0,45.06,36H14.94A9,9,0,0,0,7,28.06V17.94A9,9,0,0,0,14.94,10ZM7,30.08A7,7,0,0,1,12.92,36H7ZM47.08,36A7,7,0,0,1,53,30.08V36ZM53,15.92A7,7,0,0,1,47.08,10H53Z"/><path class="b2b6be9f-a964-4200-b524-b627b7f965e6" d="M30,31a8,8,0,1,0-8-8A8,8,0,0,0,30,31Zm0-14a6,6,0,1,1-6,6A6,6,0,0,1,30,17Z"/></g></g></svg>
                </div>
                <div className="desc">
                  <p>{desc}</p>
                </div>
              </div>
            </div>
            <div className="faq">
              <div className="row">
                <div className="header-faq">
                  <h3>{faq1.title}</h3>
                  <div className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12"><defs></defs><title>Zasób 2</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_5" data-name="Warstwa 5"><polygon class="cls-1" points="0.43 12 0 11.45 5.97 6 0 0.55 0.43 0 7 6 0.43 12"/></g></g></svg>
                  </div>
                </div>
                <div className="content">
                  <div dangerouslySetInnerHTML={ { __html: faq1.editor } } />
                </div>
              </div>
              <div className="row">
                <div className="header-faq">
                  <h3>{faq2.title}</h3>
                  <div className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12"><defs></defs><title>Zasób 2</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_5" data-name="Warstwa 5"><polygon class="cls-1" points="0.43 12 0 11.45 5.97 6 0 0.55 0.43 0 7 6 0.43 12"/></g></g></svg>
                  </div>
                </div>
                <div className="content">
                  <div dangerouslySetInnerHTML={ { __html: faq2.editor } } />
                </div>
              </div>
              <div className="row">
                <div className="header-faq">
                  <h3>{faq3.title}</h3>
                  <div className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12"><defs></defs><title>Zasób 2</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_5" data-name="Warstwa 5"><polygon class="cls-1" points="0.43 12 0 11.45 5.97 6 0 0.55 0.43 0 7 6 0.43 12"/></g></g></svg>
                  </div>
                </div>
                <div className="content">
                  <div dangerouslySetInnerHTML={ { __html: faq3.editor } } />
                </div>
              </div>
            </div>
            <div className="navigation">
              <a href={navBtnHref1} target="__blank">{navBtnTitle1}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12"><defs></defs><title>Zasób 2</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_5" data-name="Warstwa 5"><polygon class="cls-1" points="0.43 12 0 11.45 5.97 6 0 0.55 0.43 0 7 6 0.43 12"/></g></g></svg></a>
              <a href={navBtnHref2} target="__blank">{navBtnTitle2}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12"><defs></defs><title>Zasób 2</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_5" data-name="Warstwa 5"><polygon class="cls-1" points="0.43 12 0 11.45 5.97 6 0 0.55 0.43 0 7 6 0.43 12"/></g></g></svg></a>
              <a href={navBtnHref3} target="__blank">{navBtnTitle3}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12"><defs></defs><title>Zasób 2</title><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_5" data-name="Warstwa 5"><polygon class="cls-1" points="0.43 12 0 11.45 5.97 6 0 0.55 0.43 0 7 6 0.43 12"/></g></g></svg></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
