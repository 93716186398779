import { useState } from 'react';

const Button = (props) => {

    return (
        <div className="btn-wrapper">
            <input type="submit" value={props.title} onClick={() => props.action()} />
        </div>
    )
}

export default Button;